<template>
  <div>
    <el-row>
      <el-col :span="6" class="treeBox" v-loading="loading">
        <el-input
          prefix-icon="el-icon-search"
          placeholder="输入关键字进行过滤"
          v-model="filterText"
        ></el-input>
        <el-tree
          class="filter-tree"
          :data="treeList"
          default-expand-all
          :props="defaultProps"
          highlight-current
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
          ref="tree"
          accordion
        ></el-tree>
      </el-col>

      <el-col :span="18">
        <el-form
          :model="urlTree"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item>
            <el-input v-model="urlTree.name" disabled class="showInput">
              <template slot="prepend">名称</template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="urlTree.url" disabled class="showInput">
              <template slot="prepend">url</template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="urlTree.method" disabled class="showInput">
              <template slot="prepend">method</template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-link type="primary" :underline="false"
              >是否为node节点&nbsp;&nbsp;</el-link
            >

            <el-switch
              v-show="urlTree.node == 0"
              v-model="urlTree.node"
              disabled
              active-color="#13ce66"
              inactive-color="#ff4949"
              :inactive-value="urlTree.node"
            ></el-switch>

            <el-switch
              v-show="urlTree.node == 1"
              v-model="urlTree.node"
              disabled
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="urlTree.node"
            ></el-switch>

            <el-switch
              v-show="urlTree.node == 2"
              v-model="urlTree.node"
              disabled
            ></el-switch>
          </el-form-item>

          <el-form-item>
            <el-button
              v-throttle
              type="success"
              size="medium"
              icon="el-icon-edit"
              @click="edit"
              >编辑</el-button
            >
            <el-button
              v-throttle
              type="danger"
              size="medium"
              icon="el-icon-delete"
              @click="remove"
              >删除</el-button
            >
            <el-button
              v-throttle
              type="primary"
              size="medium"
              icon="el-icon-plus"
              @click="addChildrenApi"
              id="addChildren"
              >添加子接口</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-row>
      <el-dialog
        v-if="dialogVisible"
        :title="title"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-form
          :model="formApi"
          :rules="rules"
          ref="formApi"
          label-width="80px"
        >
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="formApi.name"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入名称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="url" prop="url">
            <el-input
              type="textarea"
              v-model="formApi.url"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入url"
              maxlength="100"
              show-word-limit
              :rows="4"
            ></el-input>
          </el-form-item>

          <el-form-item label="method" prop="method">
            <el-input
              v-model="formApi.method"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入method"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label prop="node">
            <el-link type="primary" :underline="false"
              >是否为node节点&nbsp;&nbsp;</el-link
            >
            <el-switch
              v-model="formApi.node"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-throttle
            type="primary"
            @click="save('formApi')"
            :loading="confirmLoading"
            >确认</el-button
          >
          <el-button v-throttle @click="cancel('formApi')">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>
<script>
import {
  getApiList,
  getApi,
  addApi,
  updateApi,
  removeApi,
} from '@/api/user/roleApiManage.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'roleInterfaceManage',
  components: {},
  data() {
    return {
      loading: false,
      urlTree: {
        name: '',
        url: '',
        node: 0,
        method: '',
        parentId: '',
      },
      selectedNode: {},
      filterText: '',
      treeList: [],
      defaultProps: {
        children: 'childrenAuthApi',
        label: 'name',
      },
      title: '',
      apiText: '',
      dialogVisible: false,
      formApi: {
        name: '',
        url: '',
        method: '',
        node: 0,
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        url: [{ required: true, message: '请输入url', trigger: 'blur' }],
        method: [{ required: true, message: '请输入method', trigger: 'blur' }],
        node: [{ required: true, message: '请输入node', trigger: 'blur' }],
      },
      confirmLoading: false,
      submitForm: {},
    }
  },
  mounted() {
    this.init()
    this.urlTree.node = 2
  },
  methods: {
    init() {
      this.initTree()
    },
    initTree() {
      this.loading = true
      const sysAuthApiId = 0
      getApiList(sysAuthApiId)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.treeList = res.data
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate)
    },
    //点击node时触发
    handleNodeClick(data) {
      this.urlTree = {}
      this.urlTree = data
      this.selectedNode = {}
      this.selectedNode = data
    },
    edit() {
      if (isEmpty(this.selectedNode.sysAuthApiId)) {
        this.msgWarning('请先选择接口')
        return
      }
      this.apiText = 'edit'
      this.title = '编辑接口'
      this.dialogVisible = true
      this.formApi = {}
      getApi(this.selectedNode.sysAuthApiId)
        .then((res) => {
          if (res.success) {
            this.submitForm = res.data
            this.$set(this.formApi, 'name', this.submitForm.name)
            this.$set(this.formApi, 'url', this.submitForm.url)
            this.$set(this.formApi, 'method', this.submitForm.method)
            this.$set(this.formApi, 'node', this.submitForm.node)
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    remove() {
      if (isEmpty(this.selectedNode.sysAuthApiId)) {
        this.msgWarning('请先选择接口')
        return
      }
      this.title = '删除接口'
      this.globalMessageBox(
        '确实删除api(包括所在下级)吗?',
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          let loading = this.globalLoading('正在删除...')
          const idList = []
          idList.push(this.selectedNode.sysAuthApiId)
          removeApi(idList)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('删除成功')
                this.initTree()
              } else {
                this.msgError('删除失败')
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    addChildrenApi() {
      if (isEmpty(this.urlTree.parentId)) {
        this.msgWarning('请先选择接口')
        return
      }
      this.apiText = 'add'
      this.title = '添加接口'
      this.dialogVisible = true
      this.formApi = {}
      this.formApi.parentId = this.urlTree.sysAuthApiId
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.apiText == 'add') {
            let loading = this.globalLoading('正在添加...')
            addApi(this.formApi)
              .then((res) => {
                loading.close()
                this.dialogVisible = false
                if (res.success) {
                  this.msgSuccess('添加成功')
                  this.initTree()
                } else {
                  this.msgError('添加失败')
                }
              })
              .catch(() => {
                loading.close()
              })
          } else if (this.apiText == 'edit') {
            let loading = this.globalLoading('正在修改...')
            this.$set(this.submitForm, 'name', this.formApi.name)
            this.$set(this.submitForm, 'url', this.formApi.url)
            this.$set(this.submitForm, 'method', this.formApi.method)
            this.$set(this.submitForm, 'node', this.formApi.node)
            updateApi(this.submitForm)
              .then((res) => {
                loading.close()
                this.dialogVisible = false
                if (res.success) {
                  this.msgSuccess('修改成功')
                  this.initTree()
                  this.$set(this.selectedNode, 'name', this.submitForm.name)
                  this.$set(this.selectedNode, 'url', this.submitForm.url)
                  this.$set(this.selectedNode, 'method', this.submitForm.method)
                  this.$set(this.selectedNode, 'node', this.submitForm.node)
                } else {
                  this.msgError('修改失败')
                }
              })
              .catch(() => {
                loading.close()
              })
          }
        } else {
          return false
        }
      })
    },
    handleClose() {},
    cancel() {
      this.dialogVisible = false
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
}
</script>
<style>
.treeBox {
  height: calc(100vh - 108px);
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 20px;
  border-right: 1px solid #ccc;
}

.showInput {
  width: 300px;
}

#addChildren {
  /* margin-left: 69px; */
}
</style>
